import(/* webpackMode: "eager" */ "/vercel/path0/lib/components/header/header.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeSwitcher"] */ "/vercel/path0/lib/components/theme-switcher/theme-switcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Figtree\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font\"}],\"variableName\":\"FIGTREE\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Montagu_Slab\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--stylized-font\"}],\"variableName\":\"MONTAGU\"}");
